import CircularProgress from "../interface/CircularProgress";

const InProgress = () => {
  return (
    <>
      <h1>Work in progress...</h1>
      <CircularProgress />

      <h2>...but you can check my linkedin profile :</h2>
      <a href="https://www.linkedin.com/in/jérémie-fauveau" target="blank">
        <img src="lk.svg" alt="logo_linkedin" />
      </a>
    </>
  );
};

export default InProgress;
